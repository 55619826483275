







































































































































































































































































































import Vue from 'vue';
import moment from 'moment';
import { message } from 'ant-design-vue';
import * as couponApi from '@/api/couponTemplate';
import selectApplication from './selectApplication/index.vue';
const initForm = {
  draft: '', //草稿标识
  name: '', //活动名称
  expiredType: undefined, //有效期类型
  validDay: undefined, //动态时间
  absoluteTime: [], //固定时间
  availableAppType: 0, //适用范围类型
  productNames: '', //适用范围文字
  skuIds: [], //适用范围id
  eventConfigIds: [], //触发条件
  strategy: undefined, //使用规则
  discount: undefined, //折扣
  firstPrice: undefined, //满(元)
  secondPrice: undefined, //减(元)
  totalMaxObtainTimes: undefined, //派发总数(张)
  peerMaxObtainTimes: undefined, //领取次数
  useMaxCountPerAgency: undefined, //使用次数
  useCondition: undefined, //使用条件
  description: '', //备注
  couponDefinitionCode: '', //优惠券编码
};
export default Vue.extend({
  name: 'createCoupon',
  components: { selectApplication },
  props: {
    applicationList: {
      type: Array,
      default: () => [],
    },
    eventList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      modalTitle: '新建',
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      couponForm: {} as any,
      couponFormRules: {
        name: [
          {
            required: true,
            message: '请输入活动名称',
            trigger: 'blur',
          },
        ],
        expiredType: [
          {
            required: true,
            message: '请选择有效期类型',
          },
        ],
        validDay: [
          {
            required: true,
            message: '请输入动态时间',
          },
        ],
        absoluteTime: [
          {
            required: true,
            message: '请输入固定时间',
          },
        ],
        availableAppType: [
          {
            required: true,
            message: '请选择适用范围类型',
          },
        ],
        productNames: [
          {
            required: true,
            message: '请选择适用范围',
          },
        ],
        strategy: [
          {
            required: true,
            message: '请选择使用规则',
          },
        ],
        discount: [
          {
            required: true,
            message: '请输入折扣',
          },
        ],
        firstPrice: [
          {
            required: true,
            message: '请输入金额',
          },
        ],
        secondPrice: [
          {
            required: true,
            message: '请输入金额',
          },
        ],
      },
      formLoading: false, //表单按钮加载状态
    };
  },
  methods: {
    moment,
    show() {
      this.modalTitle = '新建';
      this.showModal = true;
      this.couponForm = { ...initForm };
    },
    edit(record) {
      this.modalTitle = '编辑';
      // this.showModal = true;
      // this.couponForm = {
      //   ...record,
      //   absoluteTime: [moment(record.startTime), moment(record.endTime)],
      // };
      couponApi.getCouponRecordsDetail(record.sequenceNbr).then((res) => {
        if (res.status === 200) {
          const currentData = res.result;
          this.couponForm = {
            ...currentData,
            productNames: currentData.availableAppType
              ? '全部范围'
              : currentData.productNames,
            absoluteTime: [
              moment(currentData.startTime),
              moment(currentData.endTime),
            ],
          };
          this.showModal = true;
        }
      });
    },
    //范围选择类型change
    scopeTypeChange() {
      // console.log('availableAppType:', this.couponForm.availableAppType);
      if (this.couponForm.availableAppType === 1) {
        this.couponForm.productNames = '全部范围';
      } else {
        this.couponForm.productNames = '';
      }
      this.couponForm.skuIds = [];
    },
    //选择商品
    selectAvailableApp() {
      (this.$refs.selectApplication as HTMLFormElement).show(
        this.couponForm.skuIds
      );
    },
    //选择商品成功回调
    selectAppSuccess(currentData) {
      console.log('selectAppSuccess:', currentData);
      this.couponForm.skuIds = currentData.checkedKeys;
      this.couponForm.productNames = currentData.checkedNodesInfo.join(',');
    },
    //有效期类型change
    expiredTypeChange() {
      this.couponForm.validDay = undefined;
      this.couponForm.absoluteTime = undefined;
      (this.$refs.couponForm as HTMLFormElement).clearValidate();
    },
    // 使用规则change
    strategyChange() {
      this.couponForm.discount = undefined;
      this.couponForm.firstPrice = undefined;
      this.couponForm.secondPrice = undefined;
      (this.$refs.couponForm as HTMLFormElement).clearValidate();
    },
    handleFormCancel() {
      this.showModal = false;
      (this.$refs.couponForm as HTMLFormElement).resetFields();
    },
    handleModalCancel() {
      (this.$refs.couponForm as HTMLFormElement).resetFields();
    },
    handleFormSave(draft) {
      //draft默认true是草稿,false是发布
      (this.$refs.couponForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          if (this.couponForm.absoluteTime) {
            const [startTime, endTime] = this.couponForm.absoluteTime;
            this.couponForm.startTime = startTime.valueOf();
            this.couponForm.endTime = endTime.valueOf();
          }
          if (this.couponForm.couponDefinitionCode) {
            //编辑接口;
            this.editCouponRecords(draft);
          } else {
            //新增接口;
            this.addCouponRecords(draft);
          }
        }
      });
    },
    addCouponRecords(draft) {
      this.formLoading = true;
      couponApi
        .addCouponRecords({
          ...this.couponForm,
          draft,
        })
        .then((res) => {
          if (res.status === 200) {
            message.success('操作成功！');
            this.handleFormCancel();
            this.$emit('formSaveSuccess');
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    editCouponRecords(draft) {
      this.formLoading = true;
      couponApi
        .editCouponRecords({
          ...this.couponForm,
          draft,
        })
        .then((res) => {
          if (res.status === 200) {
            message.success('操作成功！');
            this.handleFormCancel();
            this.$emit('formSaveSuccess');
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    range(start, end) {
      const result = [] as any;
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledRangeTime(_, type) {
      if (type === 'end') {
        return {
          disabledHours: () => this.range(0, 23),
          disabledMinutes: () => this.range(0, 59),
          disabledSeconds: () => this.range(0, 59),
        };
      }
    },
  },
});
